import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { NavLink } from "react-router-dom";
import API from 'app/api/axios';
import { API_URL } from 'config';

export function FooterNavBarComponent() {
    
    useEffect(() => {
        getNav();
    }, []);

    const [nav, setNav] = useState<any[]>([])
   
    async function getNav() {
    
        await API.get(API_URL.CORP_API_URL+`/menu/footer`)
            .then(res => {
                const menu = res.data.data.menu;
                setNav(menu); 
            })
    }
     
 
    return (
        <>
            <FooterNavBar>
                <div className="justify-content-end  custom-nav">
                    <ul className="footer-nav flex-wrap justify-content-center">
                    { nav.map((item, index) => {
                        return (
                            <li key={index}>
                                <NavLink 
                                    exact
                                    className={"nav-link" + ("subMenu" in item  ? ' dropdown-toggle' : '')}   
                                    to={"/"+(item.slug !== '/' ? item.slug : '')} >{item.title}
                                </NavLink>
                                {"subMenu" in item ? (
                                    <ul className="footer-menu">
                                        { item.subMenu.map((subItem, subIndex) => {
                                            return (
                                                    <li className="nav-item dropdown" key={index+subIndex}>
                                                        <NavLink 
                                                            className="nav-link"
                                                            exact
                                                            to={"/"+(subItem.slug !== '/' ? subItem.slug : '')} >{subItem.title}
                                                        </NavLink>
                                                    </li>
                                            )
                                        })}
                                    </ul>
                                ): ''}
                            </li>
                        )
                    })}
                    </ul>
                    
                </div>
            </FooterNavBar>
        </>
    );
}

const FooterNavBar = styled.div``;