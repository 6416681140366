import React, { useState, useEffect } from 'react';
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import API from 'app/api/axios';
import { MERCHANT_URLS } from 'app/api/url';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/scrollbar';
import { Link } from 'react-router-dom';

export default (props) => {
    const [sliders, setSliders] = useState<any[]>([])
    SwiperCore.use([Autoplay, EffectFade])

    useEffect(() => {
        getSlider(props.id);
    }, [props]);

    async function getSlider(id) {
    
        await API.get(MERCHANT_URLS.SLIDER+`/`+id)
            .then(res => {
                const sliderList = res.data.data.slider;
                setTimeout(() => {
                    setSliders(sliderList)
                }, 1);
            })
    }

    function validateURL(link)
    {
        if (link.indexOf("http://") == 0 || link.indexOf("https://") == 0) {
            return true;
        }
        else{
            return false;
        }
    }

    return (
        <Swiper
            // install Swiper modules
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={0}
            slidesPerView={1}
            speed= {3000}
            initialSlide= {1}
            navigation
            autoplay={{ delay: 5000 }}
            loop={true}
            pagination={{ clickable: true }}
            
        >
            {sliders.map((slider, index) => (
                
                    <SwiperSlide key={index} >
                        <div className="banner"  key={'banner_' + index}>
                            <img className="myclassName" src={slider.image_url} width="1000" height="668" /><br />
                            <div className="pos-a banner-inner col-md-7 col-12 col-sm-8 pa0">
                                <div className="bg-green banner-text">
                                    <h2 className="text-white">{slider.heading}</h2>
                                    <p className="text-white">{slider.paragraph}</p>
                                    <p>
                                    {
                                        slider.button_path != null && slider.button_path != ''
                                            ? validateURL(slider.button_path) 
                                                ? <a target="_blank" href={slider.button_path} className="btn blue-btn br4 fsize21 text-white float-left custom-btn font-weight-semibold btn-width-m">{ slider.button_label != '' ? slider.button_label : 'Learn More'}</a>
                                                : <Link to={slider.button_path} className="btn blue-btn br4 fsize21 text-white float-left custom-btn font-weight-semibold btn-width-m"> { slider.button_label != '' ? slider.button_label : 'Learn More'}</Link>
                                            : ''

                                    }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
            
            ))}
        </Swiper>
    );
};