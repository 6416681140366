/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import favIcon from 'assets/img/fav.png';

import { useTranslation } from 'react-i18next';

import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../assets/scss/fonts.css';
import '../assets/scss/style.css';
import { MerchantLogin } from './pages/Auth/login';
import PrivateRoute  from './components/PrivateRoute';
import { NotFoundPage } from './components/NotFoundPage';


export function App() {
  const { i18n } = useTranslation();

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s"
        defaultTitle="Meridiana"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Meridiana" />
        <link rel="icon" href={favIcon}></link>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

        <Switch>
          <Route exact path="/login" component={MerchantLogin} />
          <Route exact path="/not-found" component={NotFoundPage} />
          <PrivateRoute />
        </Switch>
    </BrowserRouter>
  );
}
