import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { NavLink } from "react-router-dom";
import API from 'app/api/axios';
import { API_URL } from 'config';
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {isDesktop, isMobileSafari} from 'react-device-detect';

export function HeaderNavBarComponent() {
    
    useEffect(() => {
        getNav();
    }, []);

    const [nav, setNav] = useState<any[]>([])
   
    async function getNav() {
    
        await API.get(API_URL.CORP_API_URL+`/menu/header`)
            .then(res => {
                const menu = res.data.data.menu;
                setNav(menu); 
            })
    }
   
    function hideMenu(e) {
        let menu = document.getElementById("navbarSupportedContent")
        menu?.classList.remove("show")
        hideSubMenu()
    }

    function hideSubMenu() {
        if (!isDesktop || isMobileSafari) {
            var dropdowns = Array.from(document.getElementsByClassName('dropdown-menu') as HTMLCollectionOf<HTMLElement>)
            // Get and Hide all Submenus
            var i;
            for (i = 0; i < dropdowns.length; i++) {
                dropdowns[i].style.display = "none";
            }
        }
    }

    function slideMenu(event) {
        if (!isDesktop || isMobileSafari) {
            event.target.nextElementSibling.style.display = event.target.nextElementSibling.style.display == "block" ? "none" : "block"
        }
    }
   
 
    return (
        <>
            <HeaderNavBar>
                <button className="navbar-toggler toggler-color" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end  custom-nav" id="navbarSupportedContent">
                    <ul className="navbar-nav flex-wrap justify-content-end">
                    { nav.map((item, index) => {
                        return (
                            <li className={"nav-item dropdown" + ("subMenu" in item  ? ' dropdown-toggle' : '')} key={index}>
                                <NavLink 
                                    exact
                                    onClick={hideMenu}
                                    className="nav-link"   
                                    to={"/"+(item.slug !== '/' ? item.slug : '')} >{item.title}
                                </NavLink>
                                {"subMenu" in item ? (
                                 <>  
                                    <span className="menu-icon" onClick={slideMenu} >
                                        <FontAwesomeIcon icon={faCaretDown} size="lg"  />
                                    </span>
                                    <ul className="dropdown-menu  dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                                        { item.subMenu.map((subItem, subIndex) => {
                                            return (
                                                    <li className="nav-item dropdown" key={index+subIndex}>
                                                        <NavLink 
                                                            className="nav-link"
                                                            exact
                                                            onClick={hideMenu}
                                                            to={"/"+(subItem.slug !== '/' ? subItem.slug : '')} >{subItem.title}
                                                        </NavLink>
                                                    </li>
                                            )
                                        })}
                                    </ul></>
                                ): ''}
                            </li>
                        )
                    })}
                    </ul>
                    <button
                        className="hide btn btn-outline-success blue-btn text-white border-none  signup font-weight-bold mt30-m"
                        type="submit">Sign Up</button>
                </div>
            </HeaderNavBar>
        </>
    );
}

const HeaderNavBar = styled.div``;