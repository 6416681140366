import axios from 'axios';
import { API_URL } from 'config';


const API = axios.create({
  baseURL: API_URL.CORP_API_URL,
});

API.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token; // for Spring Boot back-end
      config.headers['Accept'] = 'application/json'; // for Spring Boot back-end
      config.headers['Content-Type'] = 'application/json'; // for Spring Boot back-end
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

API.interceptors.response.use(
  response => response, 
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem('token')
      localStorage.removeItem('auth')
      window.location = '/';
    }
    return Promise.reject(error);
  }
)

export default API;
