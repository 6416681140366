import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { MERCHANT_URLS } from 'app/api/url';
import API from 'app/api/axios';
import { toast, ToastContainer } from 'react-toastify';

const HandRaiserForm = (props) => {
    const [loading, setLoading] = useState(false);
    const [province, setProvince] = useState([]);
    const [email, setEmail] = useState('');
    
    
    useEffect(() => {
        fetchProvinence();

        const auth = localStorage.getItem('auth');
        if(auth) {
            setEmail(JSON.parse(auth)['email']);
        }
    }, []);


    async function fetchProvinence() {
    
        await API.get(MERCHANT_URLS.PROVINCE)
            .then(res => {
                const province = res.data;
                setProvince(province.data)
            })
    }

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            pharmacy_name: '',
            provinence: '',
            patient_count: '',
        },
        validationSchema: Yup.object({
            first_name: Yup.string().required('First Name is Required').max(20, 'The "First Name" field may not be greater than 20 characters'),
            last_name: Yup.string().required('Last Name is Required').max(20, 'The "Last Name" field may not be greater than 20 characters'),
            pharmacy_name: Yup.string().required('Pharmacy Name is Required').max(20, 'The "Pharmacy Name" field may not be greater than 20 characters'),
            provinence: Yup.string().required('Province is Required'),
            patient_count: Yup.string().required('Patient Count is Required'),
        }),
        onSubmit: values => {
            if(!loading) {
                setLoading(true)
                API.post(MERCHANT_URLS.HANDRAISERFORM, values)
                    .then(res => {
                        setLoading(false)
                        formik.resetForm();
                        const resp = res.data;
                        toast.success(resp.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        })
                    })
                    .catch(function (error) {  
                        setLoading(false)
                        formik.resetForm();
                        if (error.response) {
                            toast.error(error.response.data.data.error, {
                                position: toast.POSITION.TOP_RIGHT
                            })
                        } 
                    });    
            }     
        },
      });

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className="form-group">
                        <input 
                            type="text" 
                            name="first_name" 
                            className={"form-control" + (formik.touched.first_name && formik.errors.first_name ? ' is-invalid' : '')}   
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.first_name}
                            placeholder="First Name" />
                        {formik.touched.first_name && formik.errors.first_name ? (
                                <div className='text-left text-danger'>{formik.errors.first_name}</div>
                            ) : null}
                    </div>
                    <div className="form-group">
                        <input 
                            type="text" 
                            name="last_name" 
                            className={"form-control" + (formik.touched.last_name && formik.errors.last_name ? ' is-invalid' : '')}   
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.last_name}
                            placeholder="Last Name" />
                        {formik.touched.last_name && formik.errors.last_name ? (
                                <div className='text-left text-danger'>{formik.errors.last_name}</div>
                            ) : null}
                    </div>
                    <div className="form-group">
                        <input 
                            type="text" 
                            name="pharmacy_name" 
                            className={"form-control" + (formik.touched.pharmacy_name && formik.errors.pharmacy_name ? ' is-invalid' : '')}   
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.pharmacy_name}
                            placeholder="Pharmacy Name" />
                        {formik.touched.pharmacy_name && formik.errors.pharmacy_name ? (
                                <div className='text-left text-danger'>{formik.errors.pharmacy_name}</div>
                            ) : null}
                    </div>
            <div className="form-group">
                <select 
                    className={"form-control form-select " + (formik.touched.provinence && formik.errors.provinence ? ' is-invalid' : '')}   
                    value={formik.values.provinence}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="provinence" 
                >
                    <option hidden>Province</option>
                    {province.map((value, i) =>  <option key={i} value={value}>{value}</option>)}
                </select>
                {formik.touched.provinence && formik.errors.provinence ? (
                                <div className='text-left text-danger'>{formik.errors.provinence}</div>
                            ) : null}
            </div>
            <div className="form-group">
                <input type="email" value={email} disabled className="form-control" /></div>
            <div className="form-group">
                <select 
                    className={"form-control form-select " + (formik.touched.patient_count && formik.errors.patient_count ? ' is-invalid' : '')}   
                    value={formik.values.patient_count}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="patient_count" 
                >
                    <option>Patient Count </option>
                    <option value="1-1000">1-1,000</option>
                    <option value="1000-2000">1,000-2,000</option>
                    <option value="2000-5000"> 2,000-5,000</option>
                    <option value="5000>">More than 5,000</option>
                </select>
                {formik.touched.patient_count && formik.errors.patient_count ? (
                    <div className='text-left text-danger'>{formik.errors.patient_count}</div>
                ) : null}
            </div>
            <button type="submit" className="w-100 btn blue-btn fsize21 text-white custom-btn font-weight-semibold w-100-m">
            Submit</button>
            
            </form>
        </div>
    )
}

export default HandRaiserForm;