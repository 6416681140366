import * as React from 'react';
import styled from 'styled-components/macro';
import FooterLogo from 'assets/img/logoico.png';
import { NavLink } from 'react-router-dom';
import { FooterNavBarComponent } from '../Navbar/FooterNavBarComponent';

export function FooterComponent() {
  return (
    <>
      <Footer>
        <footer className="footer">
            <div style={{'backgroundColor':'#f0f0f0'}} className="callus-today mt0">
              <FooterNavBarComponent />
                <div className="container">
                <img src={FooterLogo} alt="" width="60px" className="mb15" />
                <h6>© Copyright 2022. <NavLink to={"/"} className="text-decoration-none">Meridiana</NavLink> – All
                    Rights Reserved.</h6>
                </div>
            </div>
        </footer>
      </Footer>
    </>
  );
}

const Footer = styled.div``;


