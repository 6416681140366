import * as React from 'react';

const LoaderComponent = (props) => {
    return (
        <>
            <div className="lightgreen"></div>
            <div className="darkgreen"></div>
        </>
    );
}

export default LoaderComponent;

