import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { MERCHANT_URLS } from 'app/api/url';
import API from 'app/api/axios';
import { toast, ToastContainer } from 'react-toastify';

const ContactUsForm = (props) => {
    const [loading, setLoading] = useState(false);
    const phoneRegExp = /(?=(?:^(?:\+?1\s*(?:[.-]\s*)?)?(?!(?:(?:.*\(.*)|(?:.*\).*)))(?:[2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))|(?:.*\((?:[2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\).*))(?:\+?1\s*(?:[.-]\s*)?)?(?:\(?([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\)?)\s*(?:[.-]\s*)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d{1,15}))?$/;///^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const emailRegExp = /^([a-z0-9\_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/;
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            name: Yup.string().required('Name is Required').max(20, 'The "Name" field may not be greater than 20 characters'),
            email: Yup.string().matches(emailRegExp, 'The "Email" field format is invalid').required('Email is Required').max(30, 'The "Email" field may not be greater than 30 characters'),
            phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
            subject: Yup.string().required('Subject is Required').max(20, 'The "Subject" field may not be greater than 20 characters'),
            message: Yup.string().required('Message is Required'),
        }),
        onSubmit: values => {
            setLoading(true)
            API.post(MERCHANT_URLS.CONTACTUSFORM, values)
                .then(res => {
                    setLoading(false)
                    formik.resetForm();
                    const resp = res.data;
                    toast.success(resp.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                })
                .catch(function (error) {
                    setLoading(false)
                    if (error.response.status === 422 ) {
                        toast.error(error.response.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        })
                    }else if (error.response) {
                        toast.error(error.response.data.data.error, {
                            position: toast.POSITION.TOP_RIGHT
                        })
                    }
                });         
        },
      });

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className="form-group">
                        <input 
                            type="text" 
                            name="name" 
                            className={"form-control" + (formik.touched.name && formik.errors.name ? ' is-invalid' : '')}   
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                            placeholder="Your Name" />
                        {formik.touched.name && formik.errors.name ? (
                                <div className='text-left text-danger'>{formik.errors.name}</div>
                            ) : null}
                    </div>
                    <div className="form-group">
                        <input 
                            type="text" 
                            name="email" 
                            className={"form-control" + (formik.touched.email && formik.errors.email ? ' is-invalid' : '')}   
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            placeholder="Email" />
                        {formik.touched.email && formik.errors.email ? (
                                <div className='text-left text-danger'>{formik.errors.email}</div>
                            ) : null}
                    </div>
                    <div className="form-group">
                        <input 
                            type="text" 
                            name="phone" 
                            className={"form-control" + (formik.touched.phone && formik.errors.phone ? ' is-invalid' : '')}   
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                            placeholder="Phone Number" />
                        {formik.touched.phone && formik.errors.phone ? (
                                <div className='text-left text-danger'>{formik.errors.phone}</div>
                            ) : null}
                    </div>
                    <div className="form-group">
                        <input 
                            type="text" 
                            name="subject" 
                            className={"form-control" + (formik.touched.subject && formik.errors.subject ? ' is-invalid' : '')}   
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.subject}
                            placeholder="Subject" />
                        {formik.touched.subject && formik.errors.subject ? (
                                <div className='text-left text-danger'>{formik.errors.subject}</div>
                            ) : null}
                    </div>
                    <div className="form-group">
                        <textarea 
                            name="message" 
                            className={"form-control" + (formik.touched.message && formik.errors.message ? ' is-invalid' : '')}   
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.message}
                            placeholder="Type Message" > </textarea>
                        {formik.touched.message && formik.errors.message ? (
                                <div className='text-left text-danger'>{formik.errors.message}</div>
                            ) : null}
                    </div>
                <button type="submit" 
                    className="w-100 btn blue-btn fsize21 text-white custom-btn font-weight-semibold w-100-m"
                    disabled={loading}
                >
                    {loading ? 'Submitting...' : 'Submit' }</button>
            
            </form>
        </div>
    )
}

export default ContactUsForm;