import React,  { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components/macro';
import { HeaderComponent } from 'app/components/Layouts/Header';
import API from 'app/api/axios';
import { useHistory, useLocation } from 'react-router-dom';
import HandRaiserForm from 'app/components/Form/HandRaiserForm';
import ReactDOM from 'react-dom'
import { ToastContainer } from 'react-toastify';
import ContactUsForm from 'app/components/Form/ContactUsForm';
import { FooterComponent } from 'app/components/Layouts/Footer';
import { API_URL } from 'config';
import SliderComponent from 'app/components/Slider/SliderComponent';
import { NotFoundPage } from 'app/components/NotFoundPage';
import LoaderComponent from 'app/components/Core/LoaderComponent';

export function PageContent({ route, navigation }) {
  const { pathname } = useLocation();
  const [content, setContent] = useState('');
  const [loader, setLoader] = useState(true)
  const history = useHistory()
  

  useEffect(() => {
      window.scrollTo(0, 0);
      fetchDataFromBackend();
  }, [pathname]);

    async function fetchDataFromBackend() {
      await API.get(API_URL.CORP_API_URL+`/page${pathname == '/' ? '/home' : pathname}`)
            .then(res => {
                const page = res.data;
                if(page !== null) {
                  setContent(page.data)
                  setLoader(false)
                  if (pathname == '/hand-raiser-form') {
                    ReactDOM.render(<HandRaiserForm />, document.getElementById('hand-raiser-form'));
                  } else if (pathname == '/contact-us') {
                    ReactDOM.render(<ContactUsForm />, document.getElementById('contact-us-form'));
                  }
                  return;
                } 
                
                setLoader(false)
            })
            .catch((error) => {
              setContent('')
              setLoader(false)
            })
    }

    return (
      <>
        <Helmet>
          { content !== null && 
            <>
              <title>{content['page_title'] ? content['page_title'] + ' | ' : ''} Meridiana</title>
              <meta name="title" content={content['meta_title'] ? content['meta_title'] : ''} />
              <meta name="description" content={content['meta_description'] ? content['meta_description'] : ''} />
            </>
          }
        </Helmet>
        <HeaderComponent />
        <ToastContainer />
        <Page>
            { 
              loader ? <div className="content"><LoaderComponent /></div>
                    : <> 
                        {(content !== '') 
                          ? <>
                              { content['slider_id'] > 0 && <SliderComponent id={content['slider_id']} /> }
                              <div className="content" dangerouslySetInnerHTML={{__html: content['html_content']}}></div> 
                            </>
                          : <NotFoundPage /> 
                        }
                      </>
            }

        </Page>
        <FooterComponent />
      </>
    );
  }
  
  const Page = styled.div``;