import React, { useEffect, useState } from "react";
import API from "app/api/axios";
import { Redirect, Route } from "react-router-dom";
import { PageContent } from "app/pages";
import { API_URL } from 'config';


const PrivateRoute = (props) => {
    const token = localStorage.getItem('token');
    const [routes, setRoutes] = useState([]);
    
    useEffect(() => {
        getRoute();
    }, []);

    async function getRoute() {
    
        await API.get(API_URL.CORP_API_URL+`/routes`)
            .then(res => {
                const route = res.data.routes;
                setRoutes(route);
            })
    }

    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        ( token != '' && token !== null ?
            <Route exact path={routes} component={PageContent} /> 
            : <Redirect to="/login" />
        )
    );
};

export default PrivateRoute;

