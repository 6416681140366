import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import Logo from 'assets/img/MeridianaLogo_Colour.png';
import { NavLink } from "react-router-dom";
import { HeaderNavBarComponent } from '../Navbar/HeaderNavBarComponent';


export function HeaderComponent() {
  const [headerClass, setStickyHeaderClass] = useState('')

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY > 86
      if (scrollCheck) {
        setStickyHeaderClass('shrink')
      } else {
        setStickyHeaderClass('')
      }
    })
  })

  return (
    <>
      <Header>
          <header className={headerClass}>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="header-wrap">
                    <nav className="navbar navbar-expand-lg navbar-dark pa0" data-overlay="" data-sticky="top">
                      <div className="container pa0 display-b">
                        <NavLink className="navbar-brand logo" to="/">
                          <img src={Logo} />
                        </NavLink>
                        <HeaderNavBarComponent />
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </header>
      </Header>
    </>
  );
}

const Header = styled.div``;


