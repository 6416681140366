import { API_URL } from 'config';

export const MERCHANT_URLS = {
  AUTH: API_URL.CORP_API_URL + '/customer/login?token=true',
  HANDRAISERFORM: API_URL.CORP_API_URL + '/handraiser/create',
  CONTACTUSFORM: API_URL.CORP_API_URL + '/contact-us',
  PROVINCE: API_URL.CORP_API_URL + '/provinence',
  SLIDER: API_URL.CORP_API_URL + '/slider'
};


