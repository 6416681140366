import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MERCHANT_URLS } from 'app/api/url';
import { toast, ToastContainer } from 'react-toastify';
import API from 'app/api/axios';
import 'react-toastify/dist/ReactToastify.css';
import Logo from 'assets/img/MeridianaLogo_Colour.png';
import { useHistory } from 'react-router-dom';

export function MerchantLogin() {
    const history = useHistory()
    const emailRegExp = /^([a-z0-9\_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/;

    useEffect(() => {
        const token = localStorage.getItem('token');

        if(token != '' && token !== null) {
            history.push('/')
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().matches(emailRegExp, 'The "Email" field format is invalid').required('Email is Required'),
            password: Yup.string()
            .required('Password is Required'),
        }),
        onSubmit: values => {

            API.post(MERCHANT_URLS.AUTH, values)
                .then(res => {
                    const resp = res.data;
                    localStorage.setItem('auth', JSON.stringify(resp.data))
                    localStorage.setItem('token', resp.token);

                    toast.success(resp.message, {
                        position: toast.POSITION.TOP_RIGHT
                    })

                    history.push('/')
                })
                .catch(function (error) {  
                    const resp = error.response;
                    if (resp) {
                        const error_message = resp.data.data.error !== undefined ? resp.data.data.error : resp.data.error

                        toast.error(error_message, {
                            position: toast.POSITION.TOP_RIGHT
                        })
                    }
                });         
        },
      });

    return (
        <>
            <Helmet>
                <title>Login | Meridiana</title>
                <meta name="description" content="Meridiana login page" />
            </Helmet>
            <ToastContainer />

            <section className="section-area pt100 handraiserform-wraper d-flex justify-content-center height-100vh">
                <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-7 col-md-8 col-12">
                        <div className="hand-raiser-form form-wraper">
                            <img src={Logo} width="200px" />
                            <h6 className="subtitle mt20">Welcome to Meridiana. We are empowering health care operators to provide their patients access to medical cannabis. Please login.</h6>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            name="email" 
                                            className={"form-control" + (formik.touched.email && formik.errors.email ? ' is-invalid' : '')}   
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                            placeholder="Email" />
                                        {formik.touched.email && formik.errors.email ? (
                                                <div className='text-left text-danger'>{formik.errors.email}</div>
                                            ) : null}
                                    </div>
                                    <div className="form-group">
                                        <input type="password" name="password"  onChange={formik.handleChange} onBlur={formik.handleBlur}
                                            value={formik.values.password} 
                                            className={"form-control" + (formik.touched.password && formik.errors.password ? ' is-invalid' : '')}
                                            placeholder="Password" />
                                        {formik.touched.password && formik.errors.password ? (
                                                                                    <div className='text-left text-danger'>{formik.errors.password}</div>
                                                                                ) : null}
                                    </div>
                                <button type="submit" className="w-100 btn blue-btn fsize21 text-white custom-btn font-weight-semibold w-100-m">
                                    Login</button>
                                    
                                </form>
                        </div>
                    </div>
                </div>
                </div>
            </section>

        </>
    );
};